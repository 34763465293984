import { Suspense, lazy } from 'react'
import './style.css'
import ReactDOM from 'react-dom/client'
import Loader from './components/Loader'
const App = lazy(() => import('./App'))

const root = ReactDOM.createRoot(document.querySelector('#root'))

root.render(
    <>
    <App />
    </>
)